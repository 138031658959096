import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { palette } from '../../styles/theme'

const ErrorFallback: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.primary.main,
        margin: '-8px',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            height: '100%',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            color: 'white',
          }}
        >
          <Typography variant="h4">
            <strong>Ocorreu um erro inesperado...</strong>
          </Typography>

          <Typography variant="h6">
            Por favor recarregue a página e tente novamente ou entre em contato
            com o suporte.
          </Typography>

          <Box display="flex" flexDirection="column" gap="30px" mt="90px">
            <Button
              type="button"
              size="large"
              onClick={() => window.location.reload()}
              sx={{
                borderColor: `white`,
                color: palette.primary.main,
                '&:hover': {
                  borderColor: 'white',
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '4px',
                height: '48px',
                textAlign: 'center',
                textTransform: 'none',
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
            >
              Recarregar
            </Button>
            <Button
              onClick={() => (window.location.href = '/dashboard')}
              sx={{
                border: `none`,
                color: 'white',
                '&:hover': {
                  border: 'none',
                  color: 'white',
                  textDecoration: 'underline',
                },
                textAlign: 'center',
                textTransform: 'none',
              }}
            >
              Voltar para a página inicial
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorFallback
